export const ReviewCardDataLeft = [
  {
    name: "Mark Welch",
    title: "Seller",
    reviewDes:
      "The Selby Team is the best and very professional. They thoroughly explained everything up front. My condo sold very quickly above the asking price! I would definitely recommend them for future sales. Their help was very much appreciated. Thanks John!",
    reviewFrom: "",
  },

  {
    name: "Jessica Fry",
    title: "Buyer",
    reviewDes:
      "We are new to the area and had no idea where to live. John listened to what we said we wanted in a home, and found us a perfect place in a great neighborhood. We are in love with our new home. John made the process pretty easy, and he's just a really nice person. I can't recommend him enough.",
    reviewFrom: "../images/rev_google.svg",
  },

  {
    name: "Niki",
    title: "Buyer",
    reviewDes:
      "Can't say enough great things about John and the Selby Team! From the beginning of our search John was very attentive, responded quickly and always had our best interest in mind. He is very knowledgeable, reliable, and professional which is super important especially when you need to act fast in such a competitive market like San Diego. It was so exciting to receive a call from John saying that the sellers accepted our offer!! His genuine joy in sharing the news was like receiving great news from a friend. It was such a memorable moment for us and we really appreciated all the hard work he put into it for us to get our home. We would highly recommend John and his team!",
    reviewFrom: "../images/rev_google.svg",
  },

  {
    name: "Matt Crusco",
    title: "Buyer and Seller",
    reviewDes:
      "We have worked with John and the Selby Team to both buy and sell several properties in San Diego. Everyone on the team is amazing to work with and John is a top tier agent that we trust to get the job done for us. He and the team routinely net us more on our listed homes and seemingly always keep escrows running smoothly. I've recommended John and team to multiple friends looking to buy a home in the area and hope to continue to work with the Selby Team for years and years to come.",
    reviewFrom: "../images/rev_google.svg",
  },
];

export const ReviewCardDataRight = [
  {
    name: "Will Suto",
    title: "Seller",
    reviewDes:
      "The Selby Team sold us our first house in San Diego. We were new to the process and the team walked us through every detail of the experience. One great benefit of working with them is that they are readily available. I remember having questions at all time of the process and they were always there to respond in a timely manner. They are professional, knowledgable, and experts in the field. If you are buying or selling a house in San Diego, I recommend them hands down.",
    reviewFrom: "../images/rev_verified.svg",
  },

  {
    name: "Maggie Tupman",
    title: "Buyer",
    reviewDes:
      "The Selby team were the reason we found our home in north San Diego county. We asked, they researched, then answered - every time. The whole team were involved with our purchase, they were consistent, on top of things and were forthcoming with the best advice. If you are considering buying a home in the San Diego area, they are the team of choice!",
    reviewFrom: "../images/rev_google.svg",
  },
  {
    name: "Susan C.",
    title: "Buyer and Seller",
    reviewDes:
      "I found the Selby Team through my neighbor who works in real estate. They responded right away to my inquiry and and within a few days had my place staged, photographed and on the market. I needed to do a contingency and Joanie went over and above to help me find the perfect new home. She is very personable and worked very hard to get me the best deal. The team has great resources and made the whole process as painless as possible. They were on my side and in my corner the whole way. I doubt you could find a better team to work with. I recommend them, without reservation!",
    reviewFrom: "../images/rev_Yelp.svg",
  },
];
