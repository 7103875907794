import React from 'react'
import ReviewCard from './ReviewCard/ReviewCard'
import LeaveOwnReview from './LeaveOwnReview/LeaveOwnReview'
import MoveYourFamily from '../Home/MoveYourFamily/MoveYourFamily'

const ReviewPage = () => {
  return (
    <>
      <div className="bg-[#fff]">
        <LeaveOwnReview />
        <ReviewCard />
        <MoveYourFamily />
      </div>
    </>
  )
}

export default ReviewPage
