import React from 'react'
import { ReviewCardDataLeft } from '../ReviewCardData'
import { ReviewCardDataRight } from '../ReviewCardData'

const ReviewCard = () => {
  return (
    <>
      <div className="pt-[40px] pb-[125px] md:pb-[70px]">
        <div className="container">
          <div className="flex gap-[48px] md:gap-[30px] 2xs:flex-col">
            <div className="flex flex-col gap-[48px] md:gap-[30px]" id="review_card">
              {ReviewCardDataLeft.map((value) => {
                return (
                  <>
                    <div className="bg-[#F7F9FA] px-[50px] md:px-[24px] py-[24px] border border-[#999999] rounded-[6px] text-center">
                      <h4 className="text-d-5lg font-[600] font-Quicksand text-[#666666] mb-[4px]">
                        {value.name}
                      </h4>

                      <p className="text-d-2lg font-[700] uppercase font-Quicksand text-[#B2B2B2] mb-[40px] sm:mb-[20px]">
                        {value.title}
                      </p>
                      <p className="text-d-3lg font-Quicksand text-[#666666] font-[500] mb-[24px]">
                        {value.reviewDes}
                      </p>
                      <img className="m-[auto]" src={value.reviewFrom} alt="" />
                    </div>
                  </>
                )
              })}
            </div>
            <div className="flex flex-col gap-[48px] md:gap-[30px]" id="review_card">
              {ReviewCardDataRight.map((value) => {
                return (
                  <>
                    <div className="bg-[#F7F9FA] px-[50px] md:px-[24px] py-[24px] border border-[#999999] rounded-[6px] text-center">
                      <h4 className="text-d-5lg font-[600] font-Quicksand text-[#666666] mb-[4px]">
                        {value.name}
                      </h4>

                      <p className="text-d-2lg font-[700] uppercase font-Quicksand text-[#B2B2B2] mb-[40px]">
                        {value.title}
                      </p>
                      <p className="text-d-3lg font-Quicksand text-[#666666] font-[500] mb-[24px]">
                        {value.reviewDes}
                      </p>
                      <img className="m-[auto]" src={value.reviewFrom} alt="" />
                    </div>
                  </>
                )
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ReviewCard
