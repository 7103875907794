import React from 'react'
import LayoutWrapper from '../component/LayoutWrapper/Index'
import ReviewPage from '../component/Reviews/index'

const Reviews = () => {
  return (
    <LayoutWrapper
      title="Testimonials | San Diego Real Estate Agency | Selby Team"
      desc="Read reviews from clients who've partnered with The Selby Team to buy, sell, or invest in San Diego homes."
      headerLogo="../../images/Selby_logo_v2.svg"
      headerId="header_v2"
    >
      <ReviewPage />
    </LayoutWrapper>
  )
}

export default Reviews
