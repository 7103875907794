import { Link } from 'gatsby'
import React from 'react'
import Button from '../../Button/Button'

const LeaveOwnReview = () => {
  return (
    <>
      <div className="pt-[125px] pb-[40px] md: pt-[160px] md:pb-[20px] sm:pt-[30px]">
        <div className="container">
          <div className="text-center">
            <h1 className="font-IbarraRealNova mb-[24px] text-d-3xl font-[600] sm:text-ms-4xl">
              Why San Diego
              <span className="font-Quicksand font-[500]"> Loves</span> The Selby
              Team
            </h1>
            <p className="font-Quicksand mb-[32px] text-d-3lg text-[#666666] font-[500]">
              Our clients consistently share their appreciation for our team
              members who help <br className='sm:hidden'/>
              them buy, sell, or invest in San Diego homes. Here's what they're
              saying.
            </p>

           {/*} <Link to="#">
              <Button
                type="solid"
                color="white"
                customStyle="px-[26px] border border-black"
              >
                <p>Leave your own Review</p>
              </Button>
  </Link> */}
          </div>
        </div>
      </div>
    </>
  )
}

export default LeaveOwnReview
